<!--
 * @Autor: syq
 * @Date: 2021-07-12 19:23:20
-->
<template>
  <div class="card-item">
    <van-card
      @click="shopdetails(item)"
      :num="item.skuQuantity"
      :price="item.actualPrice.toFixed(2)"
      :desc="item.skuSaleProperty"
      :title="item.itemName"
      :thumb="item.mediaList[0].url"
      v-for="(item, index) in card.orderBusinessLineList"
      :key="index"
    >
      <template #tag v-if="item.skuType === 30">
        <!-- <img src="../../../assets/img/package/gifts.png" alt="" /> -->
      </template>
       <template #origin-price>
          <span>{{ findPrice(item) }}</span>
       </template>
    </van-card>
    <!-- <button class="card-btn" @click="market">申请售后</button> -->
  </div>
</template>

<script>
export default {
  props: { card: Object },
  data() {
    return {};
  },
  mounted() {},
  methods: {
    findPrice(item) {
      if (item.originPrice !== item.actualPrice ) {
        return `￥${item.originPrice.toFixed(2)}`;
      } else {
        return "";
      }
    },
    market() {
      this.$router.push("/aftermarket");
    },
    shopdetails(item) {
      const { itemCode, storeCode } = item;
      this.$router.push({
        path: "/shopdetails",
        query: { itemCode: itemCode, storeCode: storeCode },
      });
    },
  },
};
</script>

<style lang="less" scoped>
.card-item {
  width: 100%;
  padding: 6px 0 0 12px;
  background-color: #fff;
  /deep/ .van-card {
    padding: 0;
    background-color: rgba(255, 255, 255, 1);
    .van-card__thumb {
      width: 85px;
      height: 85px;
      margin-right: 8px;
      img {
        border-radius: 0;
      }
    }
    .van-card__content {
      .van-card__title {
        max-height: 40px;
        font-size: 14px;
        font-weight: 500;
        color: #000;
        line-height: 20px;
      }
      .van-card__bottom {
        padding: 0 8px 0 0;
        .van-card__price {
          font-size: 16px;
          font-weight: 500;
          color: #ff3228;
          font-weight: 500;
        }
      }
    }
  }
}
</style>
