/*
 * @Autor: syq
 * @Date: 2021-07-12 16:37:32
 */

import request from "../../request/request";

// 获取前端类目树父节点集合
//订单列表
const orderBusiness = async (value, currentPage) => {
  const url = value
    ? `/orderBusiness?qp-tradeStatus-eq=${value}&qp-tradeType-eq=54&sorter=desc-createTime&currentPage=${currentPage}`
    : `/orderBusiness?qp-tradeType-eq=54&sorter=desc-createTime&currentPage=${currentPage}`;
  return request({
    url,
    method: "get",
  });
};

const triggerTrade = async (orderNo) => {
  const url = `/orderBusiness/triggerTrade/${orderNo}`;
  return request({
    url,
    method: "POST",
  });
};
const getPaymentinformation = async (id) =>
  request({
    url: `/orderBusiness/getParentOrderBusiness?qp-parentOrderNo-eq=${id}`,
    method: "get",
  });
export { orderBusiness, triggerTrade, getPaymentinformation };
